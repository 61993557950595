import { styled } from '@neui/core';
import { HStack, VStack } from '@neui/layout';
import {
  interaction___close,
  interaction___search,
  symbols___info,
  Text,
  Typography,
} from '@neui/styleguide-commerzbank';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import React, {
  createContext,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Autosuggest from 'react-autosuggest';
import { findWidgetsToRender, WIDGET_TYPES } from '@utils/WidgetChecker';
import {
  createSearchEntity,
  SearchEntityData,
  useTracker,
} from '@utils/snowplowTracking';
import { MobileChecker } from '@utils/MobileChecker';
import { useTranslation } from '@utils/i18n';
import { SectionSeparator } from '@components/SectionSeparator/SectionSeparator';
import { baseTheme, oceanTheme } from 'styling/stitches.config';
import { XColumnsGridItem } from 'page-templates/XColumnsGridItem';
import { Section } from '@components/neui-components/atoms/Section';
import {
  GA4PageType,
  GA4SearchType,
  GA4TrackInternalSearch,
} from '@utils/tracking';
import { useRuntimeSettings } from '@utils/config';

import { SuggestionChips } from './SuggestionChips';
import { SuggestionResultType } from './types';
import { CdsSuggestionsContainer } from './suggestions/CdsSuggestionsContainer';
import {
  CdsAutosuggestContainer,
  CdsSearchInputField,
  SearchIconPositioner,
  StyledBanner,
  StyledRemoveIcon,
} from '../CdsStyledComponents';
import { fetchSuggestions } from './helpers';

export const SearchCtx = createContext<SearchEntityData | null>(null);

export type SearchProps = {
  searchUrl: string;
  initialQuery: string;
  // slugMap: SlugMapType[];
  autofocus?: boolean;
  mostSearchedTerms?: string[];
  pageType: GA4PageType;
  closeSearch?: () => void;
  executeSearch: (query: string, searchType: GA4SearchType) => Promise<void>;
  onFocusChange: (focused: boolean) => void;
  setResetQuery: (resetQuery: (query: string) => void) => void;
  onSuggestionsChange: (suggestions: SuggestionResultType[]) => void;
  variant?: 'ocean' | 'base';
  compactLayout?: boolean;
};

export const CdsSearch = forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<SearchProps>
>(
  (
    {
      initialQuery,
      searchUrl,
      mostSearchedTerms,
      pageType,
      closeSearch,
      executeSearch,
      autofocus,
      onFocusChange,
      setResetQuery,
      onSuggestionsChange,
      variant,
      compactLayout,
    },
    forwardedRef,
  ) => {
    const { trackSearch } = useTracker(CdsSearch.name);
    const {
      tracking: {
        ga4: { enabled: enableGA4Tracking },
      },
    } = useRuntimeSettings();
    const { $t } = useTranslation();

    const mobileChecker = new MobileChecker();
    const mobileOs = mobileChecker.deviceType;

    const [query, setQuery] = useState(initialQuery ?? '');

    const [suggestions, setSuggestions] = useState<SuggestionResultType[]>([]);

    const inputRef = useRef<HTMLInputElement | null>();

    const searchContainerRef = useRef<HTMLDivElement>(null);
    const [searchContainerFocused, setSearchContainerFocused] = useState(false);

    useEffect(() => {
      if (autofocus && initialQuery === '') {
        inputRef.current?.focus();
      }
    }, [autofocus, initialQuery, inputRef]);

    useEffect(() => {
      if (query === '') {
        setSuggestions([]);
      }
    }, [query]);

    const resetQuery = useCallback((q: string) => {
      setQuery(q);
      setSuggestions([]);
    }, []);

    useEffect(() => {
      setResetQuery(resetQuery);
    }, [setResetQuery, resetQuery]);

    const search = async (input: string, searchType: GA4SearchType) => {
      setQuery(input);
      setSuggestions([]);

      await executeSearch(input, searchType);

      inputRef.current?.blur();
      onFocusChange(false);
    };

    const autosuggestSuggestions = [
      {
        suggestionType: 'suggestions',
        suggestions: suggestions,
      },
    ];

    const autosuggestInputProps: Autosuggest.InputProps<SuggestionResultType> =
      {
        value: query,
        onChange: (_event, { newValue }) => {
          if (!newValue.trim().endsWith('?')) {
            setQuery(newValue);
          }
        },
        onKeyPress: async (event) => {
          if ((event.key === 'Enter' || event.key === 'NumpadEnter') && query) {
            const searchType: GA4SearchType = 'Direct entry';
            enableGA4Tracking &&
              GA4TrackInternalSearch(query, searchType, pageType, undefined);
            void search(query, searchType);
          }
        },
      };

    const onSuggestionsFetchRequested: Autosuggest.SuggestionsFetchRequested =
      async ({ value, reason }) => {
        if (reason !== 'input-changed') {
          return;
        }

        const processedSuggestions = await fetchSuggestions({
          query: value,
          searchUrl,
        });

        const searchContext = createSearchEntity(
          value,
          '',
          null,
          null,
          undefined,
          processedSuggestions,
          undefined,
        );

        trackSearch?.('search_execute', [searchContext]);

        setSuggestions(processedSuggestions);
        onSuggestionsChange(processedSuggestions);
      };

    const onSuggestionSelected: Autosuggest.OnSuggestionSelected<
      SuggestionResultType
    > = (_event, { suggestionValue }) => {
      const searchType: GA4SearchType = 'Search suggestion';
      suggestionValue.length &&
        searchType === 'Search suggestion' &&
        enableGA4Tracking &&
        GA4TrackInternalSearch(
          suggestionValue,
          searchType,
          pageType,
          suggestions.findIndex(
            (suggestion) => suggestion.name == suggestionValue,
          ) + 1,
        );
      void search(suggestionValue, searchType);
      setSuggestions([]);
    };

    useEffect(() => {
      if (searchContainerFocused) {
        const searchContainer = searchContainerRef.current;

        if (searchContainer) {
          const focusableElements = searchContainer.querySelectorAll(
            'button, input, [tabindex]:not([tabindex="-1"])',
          );

          if (focusableElements.length > 0) {
            const firstElement = focusableElements[0] as HTMLElement;
            const lastElement = focusableElements[
              focusableElements.length - 1
            ] as HTMLElement;

            const handleTabKeyPress = (e: KeyboardEvent) => {
              if (e.code === 'Tab') {
                if (e.shiftKey && document.activeElement === firstElement) {
                  e.preventDefault();
                  lastElement.focus();
                } else if (
                  !e.shiftKey &&
                  document.activeElement === lastElement
                ) {
                  e.preventDefault();
                  firstElement.focus();
                }
              }
            };
            searchContainer.addEventListener('keydown', handleTabKeyPress);

            return () => {
              searchContainer.removeEventListener('keydown', handleTabKeyPress);
            };
          }
        }
      }
    }, [searchContainerFocused, suggestions]);

    useEffect(() => {
      // Added this instead of onBlur because onBlur was causing unwanted behaviour
      const closeSearchOnOuterClick = (event: MouseEvent) => {
        if (
          searchContainerRef.current &&
          !searchContainerRef.current.contains(event.target as Node)
        ) {
          setSearchContainerFocused(false);
        }
      };

      document.addEventListener('mousedown', closeSearchOnOuterClick);
      return () => {
        document.removeEventListener('mousedown', closeSearchOnOuterClick);
      };
    }, []);

    const onSearchContainerKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
      if (
        e.target === searchContainerRef.current &&
        (e.key === 'Enter' || e.key === 'NumpadEnter')
      ) {
        if (!searchContainerFocused) {
          e.preventDefault();
        }
        e.stopPropagation();
        setSearchContainerFocused(true);
        inputRef.current?.focus();
      }
    };

    const renderSuggestion: Autosuggest.RenderSuggestion<
      SuggestionResultType
    > = (suggestion, { query }) => {
      const matches = AutosuggestHighlightMatch(suggestion.name, query);
      const parts = AutosuggestHighlightParse(suggestion.name, matches);
      const handleCompletionEvent = (
        e:
          | React.KeyboardEvent<HTMLButtonElement>
          | React.MouseEvent<HTMLButtonElement, MouseEvent>,
      ) => {
        const element = e.target as HTMLElement;

        const searchContext = createSearchEntity(
          query,
          element.innerText ?? '',
          'completion',
          null,
          undefined,
          suggestions,
          undefined,
        );
        trackSearch?.('completion_click', [searchContext]);
      };

      return (
        <StyledButton
          onClick={(e) => handleCompletionEvent(e)}
          onKeyDown={(e) => handleCompletionEvent(e)}
        >
          {parts.map((part: { highlight: boolean; text: string }, index) => {
            return (
              <Typography
                key={`${part.text}-${index}`}
                renderAs="span"
                css={{ whiteSpace: 'pre' }}
                weight={part.highlight ? 'bold' : 'book'}
              >
                {part.text}
              </Typography>
            );
          })}
        </StyledButton>
      );
    };

    const renderInputComponent: Autosuggest.RenderInputComponent = (
      inputProps,
    ) => {
      /** NOTE(benjamin): Workaround for a bug in react-autosuggest making the
       * inputProps incompatible with the HTMLInputElement props signature:
       * https://stackoverflow.com/questions/61785523/autosuggest-renderinputcomponent-inputprops-types-of-property-onchange-are-i
       */
      const onChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
      ): void => {
        // ugliest type assertion ever, but it had to be done due to the bug mentioned above
        (
          inputProps.onChange as (
            e: React.ChangeEvent<HTMLInputElement>,
            f: { newValue: string; method: string },
          ) => void
        )(event, {
          newValue: event.target.value,
          method: 'type',
        });
      };

      return (
        <HStack alignItems={'center'} css={{ width: '100%' }}>
          <SearchIconPositioner
            iconPosition="left"
            animationDirection="none"
            renderAs={'div'}
            icon={interaction___search}
            aria-hidden="true"
            aria-label="search icon"
          />
          <CdsSearchInputField
            {...inputProps}
            key={'input'}
            id="search-input"
            css={compactLayout ? searchInputCompact : searchInputNotCompact}
            data-cy={'search-input'}
            placeholder={$t('SEARCH_PLACEHOLDER')}
            aria-label={`${$t('SEARCH_PLACEHOLDER')} - ${$t('PERSONAL_DATA_DISCLAIMER')}`}
            aria-controls={
              query.length > 0 ? 'react-autowhatever-1' : undefined
            }
            tabIndex={!compactLayout || searchContainerFocused ? 0 : -1}
            ref={(instance) => {
              inputRef.current = instance;
              if (typeof forwardedRef === 'function') {
                forwardedRef(instance);
              } else if (forwardedRef !== null) {
                forwardedRef.current = instance;
              }
            }}
            onChange={onChangeHandler}
            onClick={() => {
              trackSearch?.('in_field_click');
              setSearchContainerFocused(true);
            }}
            onFocus={() => {
              onFocusChange(true);
            }}
          />
          {(!compactLayout || searchContainerFocused) && (
            <StyledRemoveIcon
              data-cy={'remove-icon'}
              icon={interaction___close}
              animationDirection="top"
              iconTitle={closeSearchTitle}
              aria-label={closeSearchTitle}
              onClick={(ev) => handleCloseSearch(ev.nativeEvent)}
              tabIndex={!compactLayout || searchContainerFocused ? 0 : -1}
            />
          )}
        </HStack>
      );
    };

    const renderSuggestionsContainer: Autosuggest.RenderSuggestionsContainer = (
      params,
    ) => {
      if (query !== '' && (!compactLayout || searchContainerFocused)) {
        return (
          <CdsSuggestionsContainer
            {...params}
            showDivider={compactLayout}
            wide={!compactLayout}
            iconLook={compactLayout ? 'highlight' : 'supplementary'}
          />
        );
      } else {
        return (
          <VStack
            css={{ width: '100%', marginTop: !compactLayout ? 16 : undefined }}
            alignItems={'center'}
          >
            {compactLayout && searchContainerFocused && !query && (
              <Divider aria-hidden="true" />
            )}
            {!query &&
              mostSearchedTerms?.length !== 0 &&
              (!compactLayout || searchContainerFocused) && (
                <VStack spacing={16} role="listbox">
                  <SuggestionChips
                    aria-label={$t('SEARCH_HELP_TOPICS')}
                    mostSearchedTerms={mostSearchedTerms}
                    pageType={pageType}
                    executeSearch={async (query, searchType) => {
                      inputRef.current?.focus();
                      await search(query, searchType);
                      inputRef.current?.blur();
                    }}
                  />
                  <StyledBanner
                    aria-hidden="true"
                    icon={symbols___info}
                    size="small"
                    variant="solid"
                    look={compactLayout ? 'highlight' : 'supplementary'}
                  >
                    <Typography size={6}>
                      {$t('PERSONAL_DATA_DISCLAIMER')}
                    </Typography>
                  </StyledBanner>
                </VStack>
              )}
          </VStack>
        );
      }
    };

    const [closeSearchTitle, setCloseSearchTitle] = useState<string>('');
    useEffect(() => {
      if (query.length > 0) {
        setCloseSearchTitle('Suchfeld leeren');
      } else {
        setCloseSearchTitle('Suche schließen');
      }
    }, [query]);

    const handleCloseSearch = (ev: UIEvent) => {
      ev.preventDefault();
      ev.stopPropagation();

      if (query.length > 0) {
        setQuery('');
        inputRef.current?.focus();
      } else {
        const hasWidgets =
          suggestions?.[0]?.widget && suggestions?.[0]?.widget?.ctas.length > 0;

        let widgetsToRender: WIDGET_TYPES[] | [] = [];
        const ctas = suggestions?.[0]?.widget?.ctas ?? [];
        if (hasWidgets) {
          widgetsToRender = findWidgetsToRender(ctas, mobileOs);
        }

        const searchContext = createSearchEntity(
          query,
          $t('BACK'),
          null,
          null,
          undefined,
          suggestions ?? '',
          widgetsToRender,
        );

        trackSearch?.('back', [searchContext]);

        if (compactLayout) {
          setSearchContainerFocused(false);
        } else {
          closeSearch?.();
        }
      }
    };

    const renderSectionTitle: Autosuggest.RenderSectionTitle = (section: {
      suggestionType: string;
    }) => {
      if (section.suggestionType === 'results') {
        return <SectionSeparator />;
      }

      return (
        <>
          {autosuggestSuggestions[0].suggestions.length !== 0 && (
            <Text type={'helper'}>{$t('SEARCH_SUGGESTIONS')}</Text>
          )}
        </>
      );
    };
    return (
      <CdsAutosuggestContainer
        css={
          compactLayout
            ? {
                ...compactLayoutContainer,
                boxShadow: searchContainerFocused
                  ? '0px 16px 24px -8px rgba(0, 0, 0, 0.28)'
                  : '',
              }
            : undefined
        }
        tabIndex={compactLayout ? 0 : -1}
        ref={searchContainerRef}
        onKeyDown={compactLayout ? onSearchContainerKeyDown : undefined}
        onFocus={() =>
          !compactLayout ? setSearchContainerFocused(true) : undefined
        }
      >
        <SearchWrapper
          className={`${baseTheme} ${variant !== 'base' && oceanTheme}`}
          css={{
            paddingX: compactLayout ? 1 : '$layout-7',
            paddingY: compactLayout ? '0 !important' : undefined,
            justifyContent: 'center',
          }}
        >
          <VStack css={{ width: '100%' }} alignItems={'center'}>
            <XColumnsGridItem
              columns={{ base: 12, md: compactLayout ? 12 : 8 }}
              css={{ justifyContent: 'center' }}
            >
              <VStack css={{ width: '100%' }} spacing={compactLayout ? 0 : 16}>
                <Autosuggest
                  aria-label="test"
                  aria-hidden="true"
                  focusInputOnSuggestionClick={false}
                  suggestions={autosuggestSuggestions}
                  alwaysRenderSuggestions={true}
                  multiSection={true}
                  inputProps={autosuggestInputProps}
                  getSectionSuggestions={(s) => s.suggestions}
                  getSuggestionValue={(s) => s.name}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionSelected={onSuggestionSelected}
                  renderInputComponent={renderInputComponent}
                  renderSectionTitle={renderSectionTitle}
                  renderSuggestion={renderSuggestion}
                  renderSuggestionsContainer={renderSuggestionsContainer}
                />
              </VStack>
            </XColumnsGridItem>
          </VStack>
        </SearchWrapper>
      </CdsAutosuggestContainer>
    );
  },
);

CdsSearch.displayName = 'CdsSearch';

const SearchWrapper = styled(Section, {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  paddingY: '20px !important',
  '@sm': {
    paddingY: '28px !important',
  },
});

export const Divider = styled('hr', {
  width: '100%',
  height: '1px',
  backgroundColor: '$petrol200',
  border: 'none',
  marginY: '24px',
});

const StyledButton = styled('button', {
  width: '100%',
  textAlign: 'left',
  borderRadius: 16,
  padding: 16,
  border: 'none',
  backgroundColor: '#00000000',
  transition: 'background-color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '$background-hover',
  },
  '&:focus-visible': {
    outline: '2px solid $text-standard',
    outlineOffset: -2,
  },
});

const searchInputNotCompact = {
  marginTop: 5,
  textIndent: 48,
  '&:focus-visible': {
    borderBottom: 'solid 2px #B7C4C9',
  },
  '&:hover': {
    borderBottom: 'solid 2px #B7C4C9',
  },
};

const searchInputCompact = {
  borderBottom: 'none',
  textIndent: 34,
  minHeight: '$lineHeights$desktop-m1-6',
  fontSize: '$mobile-5',
  '@sm': {
    textIndent: 36,
    minHeight: '$lineHeights$desktop-m1-5',
    fontSize: '$desktop-m1-5',
  },
};

const compactLayoutContainer = {
  boxSizing: 'border-box',
  position: 'absolute',
  width: '100%',
  outline: '1px solid $text-helper',
  borderRadius: '30px',
  padding: '16px',
  backgroundColor: '$neutral0',
  zIndex: 2,
  transition: 'box-shadow 300ms ease-out, border 300ms ease-out',
  '& #react-autowhatever-1': {
    paddingX: '0 !important',
  },
  '& .react-autosuggest__section-title': {
    marginLeft: 0,
  },
  '& .react-autosuggest__section-container--first > .react-autosuggest__suggestions-list':
    {
      marginX: -16,
    },
  '&:hover': {
    boxShadow: '0px 16px 24px -8px rgba(0, 0, 0, 0.28)',
  },
  '&:focus-visible': {
    outline: '2px solid $text-standard',
    boxShadow: '0px 16px 24px -8px rgba(0, 0, 0, 0.28)',
  },
};
